<div class="single-consignment" [class.is-service-parts-disabled]="isServicePartsDisabled">
    <div class="form-check-title">{{ singleConsignmentInfo?.message }}</div>
    <label class="one-line-item form-check">
        <span class="form-check-label">{{ singleConsignmentInfo?.title }}</span>
        <input type="checkbox" role="checkbox"
            [class.desired-disable]="singleConsignmentInfo?.disabled && singleConsignmentInfo?.state"
            class="checkout-checkbox" [disabled]="singleConsignmentInfo?.disabled" (change)="setSingleConsignmentState($event)" [(ngModel)]="singleConsignmentInfoState">
    </label>
    <div class="delivery-info" *ngIf="singleConsignmentInfo?.messageNote">
        <cx-icon class="regular-icon grey not-active" [type]="iconTypes.INFO"></cx-icon>
        <span class="info-desc">{{ singleConsignmentInfo?.messageNote }}</span>
    </div>
</div>