import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CartTypes, HandlingOption, ShippingAccount } from "../interfaces/cart";
import { map } from "rxjs/operators";
import { PaymentTypeExtended } from "../interfaces/payment";
import { CheckoutInfoItem, ExtAddress, OrderInstructionsNotes } from "../interfaces/checkout";
import { PaymentType } from "../enums/payment-type.enum";
import { OccEndpointsService, WindowRef } from '@spartacus/core';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutStepType } from '@spartacus/checkout/base/root';
import { CheckoutStepTypeExtended } from '../enums/checkout-step-type';
import { CartType } from '../enums/cart-type.enum';

export class CheckPaymentResponse {
  available: boolean;
  code: string;
  displayName: string;
  messages: string[];
}

@Injectable({
  providedIn: "root"
})
export class CheckoutService {

  constructor(
    protected winRef: WindowRef,
    private httpClient: HttpClient,
    private occEndpoints: OccEndpointsService,
    private checkoutStepService: CheckoutStepService,
  ) { }

  getSpecialHandlingOptions(cartNumber: string, userEmail: string, shippingGroupName: string): Observable<HandlingOption[]> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userEmail}/carts/${cartNumber}/groups/${shippingGroupName}/gethandlingoptions`;
    return this.httpClient.get(url).pipe(
        map((response: any) => response.handlingOptions
            .sort((a: HandlingOption, b: HandlingOption) => (a.code > b.code ? 1 : -1)))
    );
  }

  getCheckoutInfo(cartNumber: string, userId: string, shippingGroupName: string, manufactureGroupId: string): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/carts/${cartNumber}/groups/${shippingGroupName}/manufactures/${manufactureGroupId}/getInformation`;
    return this.httpClient.get(url);
  }

  getPaymentTypes(cartNumber: string, userId: string): Observable<PaymentTypeExtended[]> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/carts/${cartNumber}/paymenttypes`;
    return this.httpClient.get(url).pipe(
        map((response: any) => response.paymentTypes)
    );
  }

  updateSpecialHandlingOptions(
      cartNumber: string,
      userEmail: string,
      shippingGroupName: string,
      optionCodes: string
  ): Observable<HandlingOption[]> {
    const params = {optionCodes};
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userEmail}/carts/${cartNumber}/groups/${shippingGroupName}/sethandlingoptions`;
    return this.httpClient.put(url, {}, {params})
        .pipe(map((response: any) => response.handlingOptions))
  }

  setCheckoutInformation(body: CheckoutInfoItem, userId: string, cartNumber: string, shippingGroupName: string, manufactureGroupId: string): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/carts/${cartNumber}/groups/${shippingGroupName}/manufactures/${manufactureGroupId}/setInformation`;
    return this.httpClient.post(url, body)
        .pipe(map((response: any) => response))
  }

  getFreightAddress(userId: string, cartId: string): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/carts/${cartId}/addresses/freight`;
    return this.httpClient.get(url);
  }

   setDeliveryAddress(addressId: any, userId: string, cartId: string , payload:any): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/carts/${cartId}/addresses/customAddress?dropShipAddressId=${addressId}`;
    return this.httpClient.post(url, payload );
  }

  addFFAddress(userId: string, cartId: string , payload:any): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/carts/${cartId}/addresses/freight`;
    return this.httpClient.post(url, payload );
  }

  checkPaymentPossibility(userId: string, cartId: string, paymentType: PaymentType): Observable<CheckPaymentResponse> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/carts/${cartId}/paymenttype?paymentTypeCode=${paymentType}`;
    return this.httpClient.get<CheckPaymentResponse>(url);
  }

  disableCheckoutSteps(cartTypes: CartTypes[] | undefined): void {
    const isSingleSubscription = cartTypes?.length == 1 && this.hasSpecificCartTypes(cartTypes, CartType.SUBSCRIPTION);
    const isServicePartsEnabled = JSON.parse(this.winRef.localStorage.getItem('isServicePartsEnabled'));

    this.checkoutStepService.disableEnableStep(
      CheckoutStepType.DELIVERY_ADDRESS,
      isSingleSubscription
    );
    this.checkoutStepService.disableEnableStep(
      CheckoutStepTypeExtended.SUBSCRIPTION_INFORMATION,
      !cartTypes?.some(ct => ct === CartType.SUBSCRIPTION.toString())
    );
    this.checkoutStepService.disableEnableStep(
      CheckoutStepTypeExtended.SPECIAL_HANDLING as unknown as CheckoutStepType,
      isSingleSubscription || isServicePartsEnabled
    );
    this.checkoutStepService.disableEnableStep(
      CheckoutStepTypeExtended.FINISHED_GOODS as unknown as CheckoutStepType,
      !(this.hasSpecificCartTypes(cartTypes, CartType.FINISHED_GOODS)) || !isServicePartsEnabled
    );
    this.checkoutStepService.disableEnableStep(
      CheckoutStepTypeExtended.SERVICE_PARTS as unknown as CheckoutStepType,
      !(this.hasSpecificCartTypes(cartTypes, CartType.SERVICE_PARTS)) || !isServicePartsEnabled
    );
  }

  hasSpecificCartTypes(cartTypes: CartTypes[] | undefined, cartType: CartType): boolean {
    return cartTypes?.some(ct => ct === cartType.toString());
  }

  getDropShipAddresses(userId: string): Observable<{message?: string, addresses?: ExtAddress[]}> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/dropShipAddresses`;
    return this.httpClient.get(url).pipe(map((res: any) => {
      return {
        addresses: res.addresses as ExtAddress[],
        message: res.message,
      };
    }));
  }

  addDropShipAddress(userId: string, address: ExtAddress): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/dropShipAddress`;
    return this.httpClient.post(url, address);
  }

  editDropShipAddress(userId: string, addressId: string, address: ExtAddress): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/dropShipAddress/${addressId}`;
    return this.httpClient.put(url, address);
  }

  deleteDropShipAddress(userId: string, addressId: string): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/dropShipAddress/${addressId}`;
    return this.httpClient.delete(url);
  }

  getDropShipAddressById(userId: string, addressId: string): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/dropShipAddress/${addressId}`;
    return this.httpClient.get(url);
  }

  getOrderInstructionsNotes(userId: string, cartId: string): Observable<OrderInstructionsNotes> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/carts/${cartId}/instructions/notes`;
    return this.httpClient.get(url);
  }

  setOrderInstructions(userId: string, cartId: string, instructions: string): Observable<any> {
    const url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/carts/${cartId}/instructions`;
    return this.httpClient.post(url, { instructions });
  }

  setDeliveryOptions(userId: string, cartId: string, shippingGroupName: string, carrier: ShippingAccount, confirmOutOfStock: boolean = false): Observable<any> {
    let url = `${this.occEndpoints.getBaseUrl()}/users/${userId}/carts/${cartId}/groups/${shippingGroupName}/setdeliveryoptions`;
    if(confirmOutOfStock) url += `?confirmOutOfStock=${confirmOutOfStock}`;
    return this.httpClient.post(url, carrier);
  }
}
