<div [cxFocus]="focusConfig" (esc)="close('Escape clicked')" class="checkout-out-of-stock-dialog">
  <div class="checkout-out-of-stock-dialog-container">
    <!-- Modal Header -->
    <div class="modal-header checkout-out-of-stock-dialog-header">
      <button (click)="close('Close Checkout Out Of Stock Dialog')" [attr.aria-label]="'common.close' | cxTranslate"
        class="checkout-out-of-stock-dialog-close close" type="button">
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="checkout-out-of-stock-dialog-body">
      <span class="confirm-message">
        {{ text }}
      </span>

      <div class="checkout-out-of-stock-dialog-footer">
        <button (click)="close('Close Checkout Out Of Stock Dialog')" [attr.aria-label]="'common.close' | cxTranslate"
          [disabled]="loading" class="btn btn-action" type="button">
          {{ 'common.cancel' | cxTranslate }}
        </button>
        <button (click)="confirmOutOfStock()" [attr.aria-label]="'common.ok' | cxTranslate" class="btn btn-primary"
          type="button" [disabled]="loading">
          {{ 'common.ok' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>