import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { CartItemContext, OrderEntry } from '@spartacus/cart/base/root';
import { CartItemComponent } from '../cart-item/cart-item.component';
import { CartItemContextSource } from '@spartacus/cart/base/components';
import { WindowRef } from '@spartacus/core';
import { Router } from "@angular/router";
import { transformNameToUrl } from "../../../../../shared/helpers/transform-name-to-url";
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { tap } from 'rxjs/operators';
import { ServiceProductType } from 'src/app/enums/service-product-type';
import { Subscription } from 'rxjs';
import { ProductExtended } from 'src/app/interfaces/product';


@Component({
  selector: '[cx-cart-item-list-row], cx-cart-item-list-row',
  templateUrl: './cart-item-list-row.component.html',
  styleUrls: ['./cart-item-list-row.component.scss'],
  providers: [
    CartItemContextSource,
    {provide: CartItemContext, useExisting: CartItemContextSource},
  ],
})
export class CartItemListRowComponent extends CartItemComponent {
  @ViewChild('promotions', {read: ElementRef}) promotions: ElementRef;
  @ViewChild('noReturnWarning', {read: ElementRef}) noReturnWarning: ElementRef;

  @Input() bundleId: string;
  @Input() maxItemsAllowed: number;
  @Input() isBundleItem: boolean;
  @Input() isBundleCollapsed: boolean;
  @Output() onBundleCollapse = new EventEmitter<boolean>();

  transformNameToUrl = transformNameToUrl;
  isSubscription: boolean;
  isReviewOrder: boolean;
  isCartPage: boolean;
  protected subscriptions = new Subscription();

  constructor(
    protected override cartItemContextSource: CartItemContextSource,
    protected winRef: WindowRef,
    protected googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    super(cartItemContextSource);
    this.isReviewOrder = this.winRef.location.href.includes('review-order') || this.winRef.location.href.includes('order-confirmation');
    this.isCartPage = this.winRef.location.href.includes('cart');
    this.subscriptions.add(
      this.cartItemContextSource.item$.pipe(tap(item => {
        this.isSubscription = Object.values(ServiceProductType).includes((item?.product as ProductExtended).serviceProductType);
      })).subscribe()
    )
  }

  onResize(hasSkipCondition?: boolean) {
    if (hasSkipCondition) {
      return;
    }
    const promotionsEl = this.promotions?.nativeElement;
    const noReturnWarningElHeight = this.noReturnWarning?.nativeElement?.clientHeight || 0;
    this.el.nativeElement.querySelectorAll('td')
      .forEach((item: HTMLElement, index: number, items: HTMLElement[]) => {
        if (this.winRef.nativeWindow.innerWidth < 992 && index !== items.length - 1) {
          this.renderer.removeStyle(item, 'padding-bottom');
          return;
        }
        if (promotionsEl) {
          this.renderer.setStyle(
            promotionsEl.firstChild,
            'bottom',
            noReturnWarningElHeight + 'px'
          );
        }
        this.renderer.setStyle(
          item,
          'padding-bottom',
          (promotionsEl?.firstChild?.clientHeight || 0) + noReturnWarningElHeight + 'px'
        );
      });
  }

  onExpandToggle() {
    this.isBundleCollapsed = !this.isBundleCollapsed;
    this.onBundleCollapse.emit(this.isBundleCollapsed);
  }

  navigateToProduct() {
    this.router.navigate(['product', this.item.product.code, this.transformNameToUrl(this.item.product.name)]);
  }

  removeEntry(item: OrderEntry) {
    const product = {
      ...item.product,
      price: item.basePrice,
    }
    this.googleAnalyticsService.sendGaEvent('remove_from_cart', {
      currency: item.totalPrice.currencyIso,
      value: item.totalPrice.value,
      items: [this.googleAnalyticsService.mapProductToGaItem(product, 0, item.quantity)]
    });
    this.quantityControl.setValue(0);
    this.quantityControl.markAsDirty();
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }
}
