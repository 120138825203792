<ng-container *ngIf="shippingGroup">
    <mat-accordion *ngIf="shippingGroup?.manufactureGroups" [multi]="true">
        <mat-expansion-panel *ngFor="let deliveryGroup of shippingGroup.manufactureGroups; let i = index;"
            (opened)="panelOpenState[i] = true" (closed)="panelOpenState[i] = false" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span *ngIf="!isSubsStep">Delivery Group #{{ deliveryGroup.manufactureGroupIndex || deliveryGroup.manufactureGroupID }}</span>
                    <span *ngIf="isSubsStep">Subscriptions</span>
                </mat-panel-title>
                <mat-panel-description (click)="$event.stopPropagation();">
                    <cx-schedule-replenishment-order
                        *ngIf="desiredShipmentDateAvailable && !isSpStep && !isSubsStep"
                        [deliveryGroup]="deliveryGroup"
                        [shippingGroupName]="shippingGroup.shippingGroupName"
                        [manufactureGroupID]="deliveryGroup.manufactureGroupID"
                        [cartCode]="cartCode"
                        (dateSelected)="getSingleConsignmentInfo(deliveryGroup.manufactureGroupID)"
                    ></cx-schedule-replenishment-order>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <table>
                <tbody>
                    <tr *ngFor="let entryGroup of deliveryGroup.entryGroups">
                        <cx-custom-cart-item 
                            *ngFor="let entry of entryGroup.entries"
                            [item]="entry"
                            [compact]="true"
                            [readonly]="true"
                        ></cx-custom-cart-item>
                    </tr>
                </tbody>
            </table>
            <generac-checkout-single-consignment
                *ngIf="!isSubsStep"
                [shippingGroupName]="shippingGroup.shippingGroupName"
                [cartCode]="cartCode"
                [manufactureGroupID]="deliveryGroup?.manufactureGroupID" 
                [singleConsignmentInfo]="deliveryGroup?.singleConsignmentInfo"
                [purchaseOrderNumber]="purchaseOrderNumber"
                [updateData]="updateDataTrigger$">
            </generac-checkout-single-consignment>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>
