<div class="customcartitem" [class.is-delivery-group]="isCheckoutPage">
    <div class="customcartitem-image-block">
        <cx-media class="cx-product-image" 
            *ngIf="(item.product.servicePart && item.product.images?.PRIMARY) || !item.product.servicePart; else spGearThumb"
            [container]="imageContainer" 
            format="product" [alt]="item.product.name || item.product.summary"></cx-media>
        <ng-template #spGearThumb>
            <img src="../../../../../../assets/icons/sp_gear_thumb.svg" alt="gear" class="sp-gear-thumb">
        </ng-template>
    </div>

    <div class="customcartitem-block">
        <div class="customcartitem-block-item">
            <div *ngIf="item.product?.code && !item.product?.displayModelNumber"
                 class="customcartitem-block-item-model">
                {{ (item.product?.servicePart ?  'cartItems.part' : 'cartItems.id') | cxTranslate }} #{{ item.product?.code }}
            </div>
            <div *ngIf="item.product?.displayModelNumber" class="customcartitem-block-item-model">
                {{ (item.product?.servicePart ?  'cartItems.part' : 'cartItems.id') | cxTranslate }} #{{ item.product?.displayModelNumber }}
            </div>
            <div class="customcartitem-block-item-name">{{item.product?.name}}</div>
            <div class="cx-code cx-delivery">
                <ng-container *ngIf="item.availabilityMessage">
                  <img src="../../../../../../assets/icons/delivery_icon_black_24.svg" />
                  <span class="availability-msg">{{ item.availabilityMessage }}</span>
                </ng-container>
                <div class="sp-product-icons" *ngIf="isServicePartsEnabled && item.product?.servicePart">
                  <img *ngIf="item.itemOnHand" src="../../../../../../assets/icons/check-green.svg">
                  <generac-sp-ltl *ngIf="item.product.ltl" [ltl]="item.product.ltl"></generac-sp-ltl>
                  <generac-sp-flammable *ngIf="item.product.flammable" [flammable]="item.product.flammable"></generac-sp-flammable>
                </div>
              </div>
        </div>
        <div class="customcartitem-block-price" *ngIf="!isCheckoutPage">
            <div class="customcartitem-block-price-title">{{ 'cartItems.itemPrice' | cxTranslate }}</div>
            <div class="customcartitem-block-price-value">{{ item.basePrice?.formattedValue }}</div>
        </div>
        <div
            *ngIf="quantityControl || (isCheckoutPage && isServicePartsEnabled)"
            [class.readonly-value]="readonly"
            [class.customcartitem-block-qty]="isCheckoutPage">
            <ng-container *ngIf="isCheckoutPage">
                <div class="customcartitem-block-qty-title">{{ 'savedCartList.quantity' | cxTranslate }}</div>
                <div class="customcartitem-block-qty-value">{{ quantityControl?.value || item?.quantity }}</div>
            </ng-container>
            <cx-custom-item-counter
                    *ngIf="!isCheckoutPage"
                    [control]="quantityControl"
                    [readonly]="!item.updateable || !!readonly || !!options.isSaveForLater"
                    [min]="+item.product?.minOrderQuantity"
                    [step]="+item.product?.addToCartQtyMultiplier"
                    [max]="+item.product?.maxOrderQuantity"
                    messagePosition="position-right"
                    [allowZero]="true"
            ></cx-custom-item-counter>
        </div>

        <div class="customcartitem-block-total">
            <div class="customcartitem-block-total-title">
                {{ 'cartItems.total' | cxTranslate }}
            </div>
            <div *ngIf="item.basePrice?.value && item.totalPrice?.currencyIso" class="customcartitem-block-total-value">
                {{ (item.basePrice?.value * item.quantity) | currency: item.totalPrice.currencyIso }}
            </div>
        </div>
        <div class="customcartitem-block-actions" *ngIf="!readonly">
            <button
                    (click)="removeItem()"
                    [cxAtMessage]="'cartItems.itemRemoved' | cxTranslate"
                    [disabled]="quantityControl.disabled"
                    [attr.aria-label]="'addToCart.removeFromCart' | cxTranslate"
                    class="link cx-action-link"
            >
                <cx-icon [type]="iconTypes.TRASH"></cx-icon>
            </button>
        </div>
    </div>
</div>