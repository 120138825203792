import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { I18nModule } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShippingAccountType } from 'src/app/enums/shipping-account-type.enum';
import { ShippingAccount, ShippingCondition } from 'src/app/interfaces/cart';
import { GeneracActiveCartService } from 'src/app/spartacus/features/cart/core/facade/active-cart.service';

@Component({
  selector: 'cx-checkout-delivery-method-selector',
  templateUrl: './checkout-delivery-method-selector.component.html',
  styleUrls: ['./checkout-delivery-method-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    I18nModule,
    NgSelectModule,
    FormsModule,
  ]
})
export class CheckoutDeliveryMethodSelectorComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  @Input() updateDeliveryMethods: Observable<ShippingAccountType>;
  @Input() generacShippingAccounts: ShippingAccount[];
  @Input() dealerShippingAccounts: ShippingAccount[];
  @Input() selectedCarrier: string;
  @Input() selectedShippingCondition: string;
  @Output() carrierOptionSelected: EventEmitter<ShippingAccount> = new EventEmitter();

  shippingAccount: ShippingAccountType;
  carriers: ShippingAccount[];
  shippingConditions: ShippingCondition[];

  constructor(
    protected checkoutStepService: CheckoutStepService,
    protected activatedRoute: ActivatedRoute,
    protected activeCartFacade: GeneracActiveCartService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.updateDeliveryMethods
      .pipe(takeUntil(this.destroy$))
      .subscribe((shippingAccount: ShippingAccountType) => {
        let shippingAccountChanged = false;
        if(this.shippingAccount && this.shippingAccount != shippingAccount) shippingAccountChanged = true;
        this.shippingAccount = shippingAccount;
        this.carriers = this.isGeneracShippingAccount(shippingAccount) ? this.generacShippingAccounts : this.dealerShippingAccounts;
        if(!this.carriers || this.carriers?.length < 1) {
          this.selectedCarrier = null;
          this.shippingConditions = null;
          this.selectedShippingCondition = null;
          this.cdr.markForCheck();
          return;
        };

        if(!this.selectedCarrier || shippingAccountChanged) this.selectedCarrier = this.carriers[0].vendorId;

        if (this.selectedCarrier) {
          this.shippingConditions = this.carriers.find((c: ShippingAccount) => c.vendorId == this.selectedCarrier)?.shippingConditions;
          if(!this.selectedShippingCondition || shippingAccountChanged) {
            this.selectedShippingCondition = this.shippingConditions[0].shippingConditionID;
          }
        }
        this.emitCarrierOptionSelected();
        this.cdr.markForCheck();
      });
  }

  carrierSelected(carrier: ShippingAccount): void {
    this.shippingConditions = carrier.shippingConditions;
    this.selectedShippingCondition = this.shippingConditions[0].shippingConditionID;
    this.emitCarrierOptionSelected();
  }

  isGeneracShippingAccount(shippingAccount: ShippingAccountType): boolean {
    return shippingAccount == ShippingAccountType.GENERAC;
  }

  shippingConditionSelected(shippingCondition: string): void {
    this.emitCarrierOptionSelected();
  }

  emitCarrierOptionSelected(): void {
    const carrier = this.carriers.find((c: ShippingAccount) => c.vendorId ==  this.selectedCarrier);
    const data: ShippingAccount = {
      ...carrier,
      shippingConditions: carrier.shippingConditions?.filter((s: ShippingCondition) => s.shippingConditionID == this.selectedShippingCondition)
    }
    this.carrierOptionSelected.emit(data);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
