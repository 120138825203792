<h4>{{ 'checkout.checkoutDeliveryMethod.title' | cxTranslate }}</h4>
<p class="select-method-text">{{ 'checkout.checkoutDeliveryMethod.selectMethod' | cxTranslate }}</p>
<div class="delivery-method-wrap">
   <div class="carrier-selector">
      <span class="carrier-selector-title">
         {{ (isGeneracShippingAccount(shippingAccount) ? 'checkout.checkoutDeliveryMethod.generacPrimaryCarrier' 
            : 'checkout.checkoutDeliveryMethod.primaryCarrier') | cxTranslate }}
      </span>
      <ng-select class="carrier-select" id="carrier-select" [searchable]="false" [clearable]="false" [items]="carriers"
         bindLabel="vendorName" bindValue="vendorId" (change)="carrierSelected($event)" [(ngModel)]="selectedCarrier"
         [ngModelOptions]="{standalone: true}"></ng-select>
   </div>
   <div class="shipping-condition-selector">
      <span class="shipping-condition-selector-title">
         {{ 'checkout.checkoutDeliveryMethod.shippingConditions' | cxTranslate }}
      </span>
      <ng-select class="shipping-condition-select" id="shipping-condition-select" [searchable]="false"
         [clearable]="false" [items]="shippingConditions" bindLabel="shippingConditionName"
         [placeholder]="'Select Shipping Condition'"
         bindValue="shippingConditionID" (change)="shippingConditionSelected($event)" [(ngModel)]="selectedShippingCondition"
         [ngModelOptions]="{standalone: true}"></ng-select>
   </div>
</div>
<p *ngIf="isGeneracShippingAccount(shippingAccount)" class="select-method-note">
   <span class="select-method-note-text">{{ 'checkout.checkoutDeliveryMethod.note' | cxTranslate }}</span>
   <br>
   <span class="select-method-note-text">{{ 'checkout.checkoutDeliveryMethod.noteCharges' | cxTranslate }}</span>
</p>