import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { I18nModule } from '@spartacus/core';
import { ShippingAccountType } from 'src/app/enums/shipping-account-type.enum';
import { GeneracActiveCartService } from 'src/app/spartacus/features/cart/core/facade/active-cart.service';

@Component({
  selector: 'cx-checkout-shipping-accounts-selector',
  templateUrl: './checkout-shipping-accounts-selector.component.html',
  styleUrls: ['./checkout-shipping-accounts-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    I18nModule,
  ]
})
export class CheckoutShippingAccountsSelectorComponent implements OnInit {

  @Input() shippingAccountSelected: ShippingAccountType;
  @Input() disableDealerShippingAccount: boolean;
  @Output() shippingAccountChanged: EventEmitter<ShippingAccountType> = new EventEmitter();

  shippingAccounts: any[] = [{
      name: 'Use Generac’s shipping account',
      code: ShippingAccountType.GENERAC,
    },
    {
      name: 'Use Dealer’s shipping account',
      code: ShippingAccountType.DEALER,
    },
  ]

  constructor(
    protected checkoutStepService: CheckoutStepService,
    protected activatedRoute: ActivatedRoute,
    protected activeCartFacade: GeneracActiveCartService,
  ) {
  }

  ngOnInit(): void {
    if(!this.shippingAccountSelected || this.disableDealerShippingAccount) {
      this.shippingAccountSelected = this.shippingAccounts[0].code;
    }
    this.shippingAccountChanged.emit(this.shippingAccountSelected);
  }

  changeShippingAccount(shippingAccount: ShippingAccountType): void {
    this.shippingAccountChanged.emit(shippingAccount);
  }
}
