import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CheckoutScheduleReplenishmentOrderComponent } from './checkout-schedule-replenishment-order.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { InfoMessageModule } from 'src/app/shared/components/info-message/info-message.module';
import { OnlyNumberDirective } from '../../../../../shared/directives/only-number/only-number.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    IconModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    InfoMessageModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutScheduleReplenishmentOrderComponent: {
          component: CheckoutScheduleReplenishmentOrderComponent,
        },
      },
    }),
  ],
  declarations: [CheckoutScheduleReplenishmentOrderComponent, OnlyNumberDirective],
  exports: [CheckoutScheduleReplenishmentOrderComponent],
})
export class CheckoutScheduleReplenishmentOrderModule {}
