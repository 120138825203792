import { NgModule } from '@angular/core';
import { translationChunksConfig, translations } from "@spartacus/assets";
import {
  FeaturesConfig,
  I18nConfig,
  OccConfig,
  provideConfig,
  provideDefaultConfig,
} from "@spartacus/core";
import { defaultB2bOccConfig } from "@spartacus/setup";
import { defaultCmsContentProviders, layoutConfig, mediaConfig } from "@spartacus/storefront";
import { environment } from 'src/environments/environment';
import { customEnglishTranslations } from "../../assets/resources/en";
import {
  defaultAddedToCartLayoutConfig,
  defaultB2BCheckoutConfig,
  defaultCheckoutRoutingConfig,
  productNormaliserConfig,
  productRoutingConfig,
  defaultVerifyAddressLayoutConfig,
  defaultStorefrontRoutesConfig,
  defaultProductImageZoomLayoutConfig,
  defaultGlobalMessageConfig,
  defaultAddToFavoritesLayoutConfig,
  defaultCreateOrEditFavoritesListLayoutConfig,
  defaultFavoritesConfirmActionLayoutConfig,
  defaultFavoriteProductsAddedToCartLayoutConfig,
  defaultWarningDialogLayoutConfig,
  defaultEditSavedAddressDialogLayoutConfig,
  defaultDeleteSavedAddressDialogLayoutConfig,
  defaultContactFactoryDialogLayoutConfig,
  defaultCheckoutOutOfStockLayoutConfig,
} from "./configs";
import { ProductUpdatedEvent } from './features/product/product-list/model/product.events';
import { cmsConfig } from './configs/cms-config';
import { providersConfig } from './configs/providers-config';
import { endpointsConfig } from './configs/endpoints-config';

@NgModule({
  declarations: [],
  providers: [
    productNormaliserConfig,
    provideConfig(layoutConfig),
    provideConfig({
      layoutSlots: {
        CartPageTemplate: {
          slots: ['TopContent', 'CenterRightContentSlot', 'EmptyCartMiddleContent', 'BottomContentSlot'],
        },
        ProductGridPageTemplate: {
            slots: ['ProductLeftRefinements', 'ProductGridSlot', 'PlaceholderContentSlot', 'ProductGridAdditionalSlot'],
        },
      }
    }),
    provideConfig(mediaConfig), 
    ...defaultCmsContentProviders, 
    provideConfig(<OccConfig>{
      backend: {
        ...environment.backend,
        loadingScopes: {
          product: {
            details: {
              reloadOn: [ProductUpdatedEvent]
            }
          }
        }
      },
    }),
    provideConfig(<OccConfig>endpointsConfig),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: translations,
        chunks: translationChunksConfig,
        fallbackLang: 'en'
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: {
          en: customEnglishTranslations
        }
      },
    }),
    provideConfig(<FeaturesConfig>{
      features: {
        level: '6.8'
      }
    }),
    provideConfig(defaultAddedToCartLayoutConfig),
    provideConfig(defaultVerifyAddressLayoutConfig),
    provideConfig(defaultB2bOccConfig),
    provideConfig(productRoutingConfig),
    provideDefaultConfig(defaultB2BCheckoutConfig),
    provideConfig(defaultCheckoutRoutingConfig),
    provideConfig(defaultStorefrontRoutesConfig),
    provideConfig(defaultProductImageZoomLayoutConfig),
    provideConfig(defaultAddToFavoritesLayoutConfig),
    provideConfig(defaultCreateOrEditFavoritesListLayoutConfig),
    provideConfig(defaultFavoritesConfirmActionLayoutConfig),
    provideConfig(defaultFavoriteProductsAddedToCartLayoutConfig),
    provideConfig(defaultWarningDialogLayoutConfig),
    provideConfig(defaultEditSavedAddressDialogLayoutConfig),
    provideConfig(defaultDeleteSavedAddressDialogLayoutConfig),
    provideConfig(defaultContactFactoryDialogLayoutConfig),
    provideConfig(defaultCheckoutOutOfStockLayoutConfig),
    ...providersConfig,
    provideConfig(defaultGlobalMessageConfig),
    provideConfig(cmsConfig)
  ]
})
export class SpartacusConfigurationModule { }
