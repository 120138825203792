import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { formConfig } from "../../../../configs/address-details-form-config";
import { FormConfig } from "../../../../../interfaces/address-details";
import { InputType } from "../../../../../enums/input-type.enum";
import { SubscriptionProduct } from "../../../../../interfaces/subscription";
import { LoadingEnum } from "../../../../../enums/loading.enum";
import { ICON_TYPE } from '@spartacus/storefront';
import { ActivatedRoute } from '@angular/router';
import { ShippingGroup, SingleConsignmentInfo } from "../../../../../interfaces/cart";
@Component({
  selector: 'cx-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddressDetailsComponent implements OnInit, AfterViewInit {
  @Input() formGroup: FormGroup;
  @Input() internalCalculation: boolean;
  @Input() isCartMixed: boolean;
  @Input() currentStepId: string;
  @Input() subscriptionProducts: SubscriptionProduct[];
  @Input() singleConsignmentInfo: SingleConsignmentInfo;
  @Input() checkoutInfo: any;
  @Input() subscriptionInfo: any[];
  @Input() cartCode: string;
  @Input() subsShippingGroup: ShippingGroup;
  @Input() isServicePartsEnabled: boolean;
  @Output() loaded = new EventEmitter<LoadingEnum>();
  InputType = InputType;
  iconTypes = ICON_TYPE;
  panelOpenState: boolean[] = [];
  formConfig: FormConfig[] = [formConfig[0]];

  constructor(
    private fb: FormBuilder,
    protected activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.initForm(this.checkoutInfo);
    if(this.subscriptionProducts?.length) {
      this.initSubscriptions();
      this.initPanel();
    }
  }

  ngAfterViewInit(): void {
    this.loaded.emit(LoadingEnum.Loaded);
  }

  getItemFormGroupByIndex(index: number) {
    return (<FormArray>this.formGroup.controls['subscriptions']).get([index]);
  }

  private initForm(checkoutInfo: any) {
    this.formConfig.forEach(field => {
      field.items.forEach(item => {
        const validators = item.validators?.length ? item.validators : [];
        this.formGroup.addControl(item.formControlName, new FormControl(checkoutInfo[item?.formControlName] || item.value, validators));
      });
    });
    if(this.subscriptionInfo?.length) {
      this.formGroup.addControl('subscriptions', this.fb.array([]));
    }
  }

  private initSubscriptions() {
    let i = 1;
    this.subscriptionInfo = this.subscriptionInfo.map((item, index) => {
      const itemWithName = {
        ...item,
        name: this.getItemTitle(item.materialNumber, i)
      };
      i = (index == this.subscriptionInfo.map(e => e.materialNumber).lastIndexOf(item.materialNumber)) ? 1 : i + 1;
      return itemWithName;
    });
  }

  private getItemTitle(materialNumber: string, index: number) {
    return this.subscriptionProducts.find(subscription => subscription.sapMaterialNumber == materialNumber)?.name + `${' #' + (index)}`;
  }

  private initPanel() {
    if(this.activatedRoute.snapshot.queryParams?.index && this.activatedRoute.snapshot.queryParams?.materialNumber) {
      const subscriptionsFiteredByMaterialNumber = this.subscriptionInfo.filter(subscription => 
        subscription.materialNumber == this.activatedRoute.snapshot.queryParams?.materialNumber);
      const indexOfItem =  this.subscriptionInfo.indexOf(subscriptionsFiteredByMaterialNumber[this.activatedRoute.snapshot.queryParams?.index]);
      this.panelOpenState[indexOfItem] = true;
    } else {
      this.panelOpenState[0] = true;
    }
  }

}
