<ng-container *ngIf="handlingOptions$ | async as handlingOptions">
    <div class="d-flex justify-content-between align-items-center" *ngIf="handlingOptions?.length > 0">
        <h2 class="cx-checkout-title d-block" [class.is-service-parts]="isServicePartsEnabled">
            {{ 'checkoutProgress.specialHandling.specialHandlingOptions' | cxTranslate | titlecase }}
        </h2>
        <cx-offline-badge *ngIf="!isServicePartsEnabled" [internalCalculation]="internalCalculation"></cx-offline-badge>
    </div>

    <p *ngIf="!isServicePartsEnabled" class="cx-checkout-text">{{ 'checkoutProgress.specialHandling.selectOptions' | cxTranslate }}</p>

    <p class="cx-checkout-text residential-delivery-selected" *ngIf="isResidentialDeliverySpecialOptionSelected">
        {{ 'checkoutProgress.specialHandling.residentialDeliverySelected' | cxTranslate }}
    </p>
</ng-container>

<form [formGroup]="formGroup">
    <div
            *ngFor="let option of handlingOptions$ | async"
            [class.checkbox-disabled]="option.disabled"
            class="form-check"
    >
        <input
            [id]="option.code"
            type="checkbox"
            role="checkbox"
            class="form-check-input"
            [value]="option.code"
            [formControlName]="option.code"
            [checked]="option.selected"
        >
        <label [for]="option.code" class="form-check-label form-checkbox-label">
        <span class="name-container">
            <span class="name">{{ option.name }}</span>
            <span *ngIf="option.price as price" class="price">
                {{ price.value | currency: price.currencyIso }}
            </span>
        </span>
            <span class="description">{{ option.description }}</span>
        </label>
    </div>
</form>

<div *ngIf="!isServicePartsEnabled" class="cx-checkout-btns row">
    <div class="col-sm-12 col-md-6">
        <button class="cx-btn btn btn-block btn-action" (click)="back()">
            {{ backBtnText | cxTranslate }}
        </button>
    </div>
    <div class="col-sm-12 col-md-6">
        <button
            class="cx-btn btn btn-block btn-primary"
            (click)="next()"
        >
            {{ 'common.continue' | cxTranslate }}
        </button>
    </div>
</div>
