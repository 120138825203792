<h2 class="summary-heading">{{ 'orderCost.orderSummary' | cxTranslate }}</h2>

<ng-container *ngIf="data$ | async as data">
    <p *ngIf="data?.subTotal" class="summary-subtotal">
        <span>{{ 'orderCost.subTotal' | cxTranslate }}</span>
        <span>{{ data.subTotal.formattedValue }}</span>
    </p>
    <p *ngIf="isServicePartsEnabled" class="summary-options">
        <span>{{ 'orderCost.shippingExtended' | cxTranslate }}</span>
        <span>{{ data?.deliveryCost && data?.deliveryAddress ? data.deliveryCost?.formattedValue : ('orderCost.tbd' | cxTranslate) }}</span>
    </p>
    <p *ngIf="!isServicePartsEnabled" class="summary-options">
        <span>{{ 'orderCost.shipping' | cxTranslate }}</span>
        <span>{{ data?.deliveryCost ? data.deliveryCost?.formattedValue : ('orderCost.tbd' | cxTranslate) }}</span>
    </p>
    <p *ngIf="isServicePartsEnabled" class="summary-options">
        <span>{{ 'orderCost.servicePartsDeliveryCost' | cxTranslate }}</span>
        <span>{{ data?.servicePartsDeliveryCost && data?.deliveryAddress ? data.servicePartsDeliveryCost?.formattedValue : ('orderCost.tbd' | cxTranslate) }}</span>
    </p>
    <p *ngIf="data?.handlingOptionsTotal && !(data?.cartTypes?.length === 1 && data?.cartTypes[0]?.cartType === 'SUBSCRIPTION')" class="summary-options">
        <span>{{ 'orderCost.specialHandling' | cxTranslate }}</span>
        <span>
            {{ data?.deliveryCost && data?.handlingOptionsTotal && !cartOrDeliveryAddressStep || (cartOrDeliveryAddressStep && data?.handlingOptionsTotal?.value > 0)
                ? data.handlingOptionsTotal?.formattedValue
                : ('orderCost.tbd' | cxTranslate)
            }}
        </span>
    </p>
    <p class="summary-options">
        <span>{{ 'orderCost.adjustment' | cxTranslate }}</span>
        <span>{{ data?.adjustment?.formattedValue }}</span>
    </p>
    <p  *ngIf="data?.tradeDiscounts?.value !== 0" class="summary-options">
        <span>{{ 'orderCost.tradeDiscounts' | cxTranslate }}</span>
        <span class="summary-discount">{{ data?.tradeDiscounts?.formattedValue }}</span>
    </p>
    <p  *ngIf="data?.promoDiscounts?.value !== 0" class="summary-options">
        <span>{{ 'orderCost.promoDiscounts' | cxTranslate }}</span>
        <span class="summary-discount">{{ data?.promoDiscounts?.formattedValue }}</span>
    </p>
    <p *ngIf="data?.totalTax" class="summary-options">
        <span><span *ngIf="!data?.deliveryAddress">Estimated </span>{{ 'orderCost.taxes' | cxTranslate }}</span>
        <span>{{ data.internalCalculation ? ('orderCost.tbd' | cxTranslate) : data?.totalTax?.formattedValue }}</span>
    </p>
    <p class="summary-grand-total">
        <span>{{ 'orderCost.grandTotal' | cxTranslate | titlecase }}</span>
        <span>{{ data?.totalPrice?.formattedValue }}</span>
    </p>
    <p *ngIf="data?.prospectOrder && data?.paymentType?.code === PaymentType.CARD && showInfoDAOrder" class="summary-card-info">
        <span>{{ 'checkoutOrderConfirmation.creditCardPaymentInfoDAOrder' | cxTranslate }}</span>
    </p>
</ng-container>