import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { GeneracActiveCartService } from '../../../cart/core/facade/active-cart.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { ShippingAccount, ShippingGroup } from 'src/app/interfaces/cart';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CartType } from 'src/app/enums/cart-type.enum';
import { ShippingAccountType } from 'src/app/enums/shipping-account-type.enum';
import { LaunchDialogService } from '@spartacus/storefront';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { LoadingEnum } from 'src/app/enums/loading.enum';

@Component({
  selector: 'cx-checkout-service-parts',
  templateUrl: './checkout-service-parts.component.html',
  styleUrls: ['./checkout-service-parts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutServicePartsComponent implements OnDestroy {
  private updateDeliveryMethodsSubject$$ = new BehaviorSubject<ShippingAccountType | null>(null);
  private destroy$ = new Subject();

  cartCode: string;
  purchaseOrderNumber: string;
  userId: string;
  shippingGroupName: string;
  updateDeliveryMethodsTrigger$ = this.updateDeliveryMethodsSubject$$.asObservable();
  generacShippingAccounts: ShippingAccount[];
  dealerShippingAccounts: ShippingAccount[];
  carrierSelected: ShippingAccount;
  selectedCarrier: string;
  selectedShippingCondition: string;
  selectedShippingAccount: ShippingAccountType = ShippingAccountType.GENERAC;
  isLtlItemExists: boolean;
  loading = LoadingEnum.Idle;
  LoadingEnum = LoadingEnum;

  shippingGroup$: Observable<ShippingGroup> = this.activeCartFacade.getActive().pipe(
    tap(cart => {
      this.checkoutService.disableCheckoutSteps(cart?.cartTypes);
      this.cartCode = cart.code;
      this.userId = cart.user?.uid;
      this.purchaseOrderNumber = cart.purchaseOrderNumber;
    }),
    switchMap(cart => of(cart.shippingGroups) as Observable<ShippingGroup[]>),
    map((shippingGroups: ShippingGroup[]) => shippingGroups.filter(sg => sg.cartTypes[0] === CartType.SERVICE_PARTS.toString())),
    switchMap(shippingGroups => of(shippingGroups[0])),
    tap(shippingGroup => this.setShippingGroupData(shippingGroup))
  )

  get backBtnText(): string {
    return this.checkoutStepService.getBackBntText(this.activatedRoute);
  }

  constructor(
    protected checkoutStepService: CheckoutStepService,
    protected activatedRoute: ActivatedRoute,
    protected activeCartFacade: GeneracActiveCartService,
    protected launchDialogService: LaunchDialogService,
    protected globalMessageService: GlobalMessageService,
    private cdr: ChangeDetectorRef,
    private checkoutService: CheckoutService,
  ) {
  }

  private setShippingGroupData(shippingGroup: ShippingGroup): void {
    this.ltlItemExists(shippingGroup);
    this.shippingGroupName = shippingGroup?.shippingGroupName;
    this.generacShippingAccounts = shippingGroup?.generacShippingAccounts;
    this.dealerShippingAccounts = shippingGroup?.dealerShippingAccounts;
    this.setSelectedDeliveryOption();
    this.loading = LoadingEnum.Loaded;
  }

  private setSelectedDeliveryOption(): void {
    let selectedDeliveryOption: ShippingAccount;
    [this.generacShippingAccounts, this.dealerShippingAccounts].forEach(carrier => {
      if (carrier) {
        carrier.forEach(shippingAccount => {
          const selectedShippingCondition = shippingAccount.shippingConditions?.find(sc => sc?.selected);
          if (selectedShippingCondition) {
            selectedDeliveryOption = {
              ...shippingAccount,
              shippingConditions: [selectedShippingCondition]
            }
          }
        })
      }
    });
    this.selectedCarrier = selectedDeliveryOption?.vendorId || this.generacShippingAccounts[0].vendorId;
    this.selectedShippingCondition = selectedDeliveryOption?.shippingConditions[0].shippingConditionID || 
      this.generacShippingAccounts[0].shippingConditions[0].shippingConditionID;
    if (selectedDeliveryOption) {
      this.selectedShippingAccount = !!selectedDeliveryOption?.generacShippingAccount ?
        ShippingAccountType.GENERAC : ShippingAccountType.DEALER;
    }
  }


  private ltlItemExists(shippingGroup: ShippingGroup) {
    this.isLtlItemExists = shippingGroup.manufactureGroups?.some(mg =>
      mg?.entryGroups?.some(eg => eg?.entries?.some(e => e?.product?.ltl))
    );
  }

  private openOutOfStockDialog(text: string) {
    const dialog = this.launchDialogService.openDialog(
      'CHECKOUT_OUT_OF_STOCK',
      undefined,
      undefined,
      { text }
    );

    if (dialog) {
      dialog.pipe(take(1), takeUntil(this.destroy$)).subscribe();
    }

    this.launchDialogService.dialogClose
      .pipe(
        takeUntil(this.destroy$),
        tap((reason) => {
          if (reason == 'confirmOutOfStock') {
            this.setDeliveryOptions(true);
          } else if(reason == 'Close Checkout Out Of Stock Dialog') {
            this.selectedShippingCondition = null;
          }
        })
      )
      .subscribe()
  }

  private setDeliveryOptions(confirmOutOfStock: boolean = false) {
    this.loading = LoadingEnum.Loading;
    this.checkoutService
      .setDeliveryOptions(this.userId, this.cartCode, this.shippingGroupName, this.carrierSelected, confirmOutOfStock)
      .pipe(
        takeUntil(this.destroy$),
        catchError((err: any) => {
          this.loading = LoadingEnum.Loaded;
          if (!!err.error?.showOutOfStockWarning) {
            this.openOutOfStockDialog(err.error?.message);
          } else {
            this.selectedShippingCondition = null;
            this.cdr.markForCheck();
            this.globalMessageService.add(err.error.errors[0].message, GlobalMessageType.MSG_TYPE_ERROR);
          }
          return of();
        })
      ).subscribe(() => {
        this.loading = LoadingEnum.Loaded;
        this.activeCartFacade.reloadActiveCart();
        this.checkoutStepService.next(this.activatedRoute)
      })
  }

  getDeliveryMethods(shippingAccount: ShippingAccountType): void {
    this.updateDeliveryMethodsSubject$$.next(shippingAccount);
  }

  setCarrier(carrier: ShippingAccount): void {
    this.carrierSelected = carrier;
    this.setSelectedDeliveryOption();
  }

  back(): void {
    this.checkoutStepService.back(this.activatedRoute);
  }

  next(): void {
    this.setDeliveryOptions();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
