import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ICON_TYPE, IconModule, SpinnerModule } from "@spartacus/storefront";
import { MSRPPrice, ProductExtended } from "../../../../../interfaces/product";
import { filter, finalize, switchMap, take } from "rxjs/operators";
import { ProductService } from "../../../../../services/product.service";
import { UserAccountFacade } from "@spartacus/user/account/root";
import { UserExtended } from "../../../../../interfaces/login";
import { SharedModule } from "../../../../../shared/shared.module";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { LoadingEnum } from "../../../../../enums/loading.enum";
import { TooltipPosition } from "../../../../../enums/tooltip-position.enum";

@Component({
  selector: 'cx-info-price-wrapper',
  templateUrl: './info-price-wrapper.component.html',
  styleUrls: ['./info-price-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IconModule,
    SharedModule,
    CurrencyPipe,
    SpinnerModule
  ]
})
export class InfoPriceWrapperComponent {

  @Input() product: ProductExtended;
  @Input() position = TooltipPosition.Left;

  isOpen = false;
  netPrice: string;
  loading = LoadingEnum.Idle;
  onHand: boolean;
  LoadingEnum = LoadingEnum;
  TooltipPosition = TooltipPosition;
  readonly iconTypes = ICON_TYPE;

  constructor(
    private productService: ProductService,
    private userAccountService: UserAccountFacade,
    private cdr: ChangeDetectorRef
  ) {
  }

  getPrice(product: ProductExtended) {
    this.isOpen = true;

    if (!product.purchasable) {
      return;
    }

    this.loading = LoadingEnum.Loading;
    this.userAccountService.get()
      .pipe(
        filter(Boolean),
        switchMap((user: UserExtended) => this.productService.getCPIPrice(user?.uid, product)),
        take(1),
        filter((price: MSRPPrice) => !!price),
        finalize(() => this.cdr.markForCheck())
      ).subscribe((price: MSRPPrice) => {
      this.netPrice = price.value;
      this.onHand = price?.onHand;
      this.loading = LoadingEnum.Loaded;
    });
  }
}
