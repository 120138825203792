<ng-container *ngIf="shippingAccounts">
    <h4>{{ 'checkout.checkoutShippingAccounts.title' | cxTranslate }}</h4>
    <p class="select-account-text">{{ 'checkout.checkoutShippingAccounts.selectAccount' | cxTranslate }}</p>
    <div class="shipping-account-selector-wrap">
        <div class="shipping-accounts-radio" *ngFor="let shippingAccount of shippingAccounts">
            <input id="shippingAccount-{{ shippingAccount.code }}" class="form-check-input" role="radio" type="radio"
                aria-checked="true" name="shippingAccounts" (change)="changeShippingAccount(shippingAccount.code)"
                [value]="shippingAccount.code" [checked]="shippingAccount.code === shippingAccountSelected" 
                [disabled]="shippingAccount.code === 'DEALER' && disableDealerShippingAccount"/>
            <label class="shipping-account-label form-check-label form-radio-label"
                for="shippingAccount-{{ shippingAccount.code }}">
                <div class="shipping-account">
                    {{ shippingAccount.name }}
                </div>
            </label>
        </div>
    </div>
    <p class="select-account-note">
        <strong class="select-account-note-title">Note: </strong>
        <span class="select-account-note-text">{{ 'checkout.checkoutShippingAccounts.note' | cxTranslate }}</span>
        <a class="select-account-note-email" href="mailto:genservice@generac.com" target="_blank">{{ 'checkout.checkoutShippingAccounts.noteEmail' | cxTranslate }}</a>
    </p>
</ng-container>