import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CartItemComponent } from "../cart-item/cart-item.component";
import { CartItemContextSource } from "@spartacus/cart/base/components";
import { CartItemContext } from "@spartacus/cart/base/root";
import { ICON_TYPE } from "@spartacus/storefront";
import { WindowRef } from '@spartacus/core';

@Component({
  selector: 'cx-custom-cart-item',
  templateUrl: './custom-cart-item.component.html',
  styleUrls: ['./custom-cart-item.component.scss'],
  providers: [
    CartItemContextSource,
    {provide: CartItemContext, useExisting: CartItemContextSource},
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomCartItemComponent extends CartItemComponent {
  isCheckoutPage: boolean;
  isServicePartsEnabled: boolean;

  constructor(
    protected override cartItemContextSource: CartItemContextSource,
    protected winRef: WindowRef,
  ) {
    super(cartItemContextSource);
    this.isCheckoutPage = this.winRef.location.href.includes('checkout/');
    this.isServicePartsEnabled = JSON.parse(this.winRef.localStorage.getItem('isServicePartsEnabled'));
  }

  protected readonly ICON_TYPE = ICON_TYPE;
}
